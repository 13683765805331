export const pricingData = {
  monthly: [
    {
      name: "Standard",
      price: "$999",
      description: "Ideal for small teams ready to explore Salesforce integration",
      features: [
        "1 Solution",
        "2 Seats",
        "Salesforce Expert Support",
        "Salesforce Technical Support",
        "Analytics",
        "Automated Workflows",
        "API and 50+ integrations",
      ],
    },
    {
      name: "Plus",
      price: "$1,699",
      description: "Designed for growing businesses seeking more capacity",
      features: [
        "2 Solutions",
        "5 Seats",
        "Salesforce Expert Support",
        "Salesforce Technical Support",
        "Analytics",
        "Automated Workflows",
        "API and 50+ integrations",
      ],
    },
    {
      name: "Company",
      price: "custom",
      description: "Tailor-made for your enterprise's unique needs.",
      features: [
        "Custom",
        "Custom",
        "Salesforce Expert Support",
        "Salesforce Technical Support",
        "Reports",
        "Automated Workflows",
        "API and 50+ integrations",
      ],
    },
  ],
  annual: [
    {
      name: "Standard",
      price: "$9,599",
      description: "Ideal for small teams ready to explore Salesforce integration",
      features: [
        "1 Solution",
        "2 Seats",
        "Salesforce Expert Support",
        "Salesforce Technical Support",
        "Analytics",
        "Automated Workflows",
        "API and 50+ integrations",
      ],
    },
    {
      name: "Plus",
      price: "$15,999",
      description: "Designed for growing businesses seeking more capacity",
      features: [
        "2 Solutions",
        "5 Seats",
        "Salesforce Expert Support",
        "Salesforce Technical Support",
        "Analytics",
        "Automated Workflows",
        "API and 50+ integrations",
      ],
    },
    {
      name: "Company",
      price: "custom",
      description: "Tailor-made for your enterprise's unique needs.",
      features: [
        "Custom",
        "Custom",
        "Salesforce Expert Support",
        "Salesforce Technical Support",
        "Reports",
        "Automated Workflows",
        "API and 50+ integrations",
      ],
    },
  ],
};
