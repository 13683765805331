import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import Container from "./Container";
import backgroundBlur from "../images/pricing-section/background.svg";
import bgUrl from "../images/hero-header/staticBackground.svg";
import ReusableButton from "./ReusableButton";

// CustomCard component for displaying pricing options
const CustomCard = ({ option, plan }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        borderRadius: "15px",
        boxShadow: "0px 4px 45px 0px #0000000D",
      }}
    >
      <CardContent
        sx={{
          padding: "20px 50px",
          textAlign: "center",
        }}
      >
        {/* Display option name */}
        <Typography variant="pricingTitle" component="title">
          {option.name}
        </Typography>

        {/* Display option price */}
        <Typography mt={1} variant="pricingPrice">
          {option.price}
        </Typography>

        {/* Display plan type */}
        <Typography my={2} component="p" variant="caption">
          {plan === "monthly" ? "Per Month" : "Per Year"}
        </Typography>

        {/* Display option description */}
        <Typography variant="body1" color={theme.palette.text.pricing} mb={2}>
          {option.description}
        </Typography>

        {/* Display option features */}
        {option.features.map((feature, index) => (
          <Box key={index} display="flex" alignItems="center" color="#13152BB2" pl={1} my={2}>
            <Box
              bgcolor="#F9FAFE"
              borderRadius="50%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              p="2px"
              mr={2}
            >
              <CheckRoundedIcon color="primary" />
            </Box>
            <Typography component="p" variant="body1" textAlign="left">
              {feature}
            </Typography>
          </Box>
        ))}

        {/* Display get started button */}
        <ReusableButton title="Get Started" link="/demo" variant="outlined" fullWidth />
      </CardContent>
    </Card>
  );
};

// HighlightedCard component for displaying highlighted pricing options
const HighlightedCard = ({ option, plan }) => {
  const theme = useTheme();
  // Similar to CustomCard but with a different design
  return (
    <Card
      sx={{
        border: "2px solid #FFA564",
        boxShadow: "0px 4px 45px 0px #0000000D",
        borderRadius: "15px",
      }}
    >
      <CardContent
        sx={{
          padding: "20px 50px",
          textAlign: "CENTER",
        }}
      >
        {/* Display option name */}
        <Typography variant="pricingTitle" component="title" color={theme.palette.text.primary}>
          {option.name}
        </Typography>

        {/* Display option price */}
        <Typography mt={1} variant="pricingPrice">
          {option.price}
        </Typography>

        {/* Display plan type */}
        <Typography my={2} component="p" variant="caption" color={theme.palette.text.primary}>
          {plan === "monthly" ? "Per Month" : "Per Year"}
        </Typography>

        {/* Display option description */}
        <Typography variant="body1" mb={2} color={theme.palette.text.pricing}>
          {option.description}
        </Typography>

        {/* Display option features */}
        {option.features.map((feature, index) => (
          <Box key={index} display="flex" alignItems="center" color="#13152BB2" pl={1} my={2}>
            <Box
              bgcolor="#F9FAFE"
              borderRadius="50%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              p="2px"
              mr={2}
            >
              <CheckRoundedIcon color="primary" />
            </Box>
            <Typography component="p" variant="body1" textAlign="left">
              {feature}
            </Typography>
          </Box>
        ))}

        {/* Display get started button */}
        <ReusableButton title="Get Started" link="/demo" variant="primary" fullWidth />
      </CardContent>
    </Card>
  );
};

// PricingSection component for displaying pricing section
const PricingSection = ({ pricingOptions }) => {
  const theme = useTheme();
  // State for storing selected pricing plan
  const [pricingPlan, setPricingPlan] = useState("monthly");

  // Handler for changing pricing plan
  const handlePricingChange = (event, newPricing) => {
    if (newPricing !== null) {
      setPricingPlan(newPricing);
    }
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box position={"relative"}>
      <Box
        sx={{
          width: "100%",
          height: "350px",
          backgroundImage: `url(${bgUrl})`,
          position: "absolute",
          top: 0,
          left: 0,
        }}
      ></Box>
      <Container position={"relative"}>
        {/* Display radio buttons for selecting pricing plan */}
        <Box mb={5} width="100%" display="flex" justifyContent="center" alignItems="center">
          <RadioGroup
            row
            aria-label="pricing plan"
            name="pricingPlan"
            value={pricingPlan}
            onChange={handlePricingChange}
          >
            <FormControlLabel
              value="monthly"
              control={<Radio />}
              label={<Typography variant={"radioBtnText"}>Monthly</Typography>}
            />
            <FormControlLabel
              value="annual"
              control={<Radio />}
              label={<Typography variant={"radioBtnText"}>Annual ( Save up to 20% )</Typography>}
            />
          </RadioGroup>
        </Box>

        {/* Display background image */}
        <Box
          component="img"
          src={backgroundBlur}
          alt="background"
          maxWidth="1400px"
          width="100%"
          position="absolute"
          bottom={0}
          right={0}
          mt={3}
          zIndex={0}
        />

        {/* Display grid of pricing options */}
        <Grid
          container
          spacing={4}
          sx={{
            background: { backgroundBlur },
          }}
        >
          {pricingOptions &&
            pricingOptions[pricingPlan].map((option, index) => (
              <Grid
                item
                data-aos={isMobile ? "fade-up" : "fade-left"}
                data-aos-delay={index * 400}
                data-aos-once={false}
                xs={12}
                sm={6}
                md={4}
                key={index}
                position="relative"
                zIndex={2}
              >
                {index === 1 ? (
                  <HighlightedCard option={option} plan={pricingPlan} />
                ) : (
                  <CustomCard option={option} plan={pricingPlan} />
                )}
              </Grid>
            ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default PricingSection;
